/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";
import BaseStore from "./BaseStore";
import ProductProperty from "./ProductProperty";
import ProductHasProperty from "./ProductHasProperty";
import ProductHasCategory from "./ProductHasCategory";
import merge from "lodash/merge";

export default class Product extends BaseModel {
    static entityName = 'product';

    static MEASURE_ITEM = 'item';
    static MEASURE_PACK = 'pack';
    static MEASURE_KG = 'kg';

    /**
     * @return array - The avail key => translate pairs
     * */
    static measures() {
        return [
            {
                value: this.MEASURE_ITEM,
                label: 'Штука'
            },
            {
                value: this.MEASURE_PACK,
                label: "Упаковка"
            },
            {
                value: this.MEASURE_KG,
                label: "Килограмм"
            }
        ];
    }


    defaults() {
        return {
            id: null,
            name: null,
            alias: null,
            price: null,
            price_action: null,
            short_text: null,
            full_text: null,
            vendor_code: null,
            internal_code: null,
            bar_code: null,
            is_active: false,
            created_at: null,
            updated_at: null,
            vendor_id: null,
            weight_kg: null,
            measure: Product.MEASURE_ITEM,
            properties: []
        }
    }

    routes() {
        return {
            ...super.routes(),
            'link-parents': '{urlPrefix}/{entityName}/link-parents',
            'properties': '{urlPrefix}/{entityName}/properties'
        }
    }

    /**
     * @return BaseStore - The configured store of parent refs
     * */
    getHasParents(options = {}) {
        if (!this._hasParents) {
            this._hasParents = new BaseStore([], {
                model: ProductHasCategory,
                ...merge({
                    fetchParams: {
                        sort: 'display_order',
                        filter: JSON.stringify([{
                            property: "product_id",
                            value: this.id
                        }])
                    }
                }, options)
            });
        }

        return this._hasParents;
    }

    getPreferredRef() {
        return this.getHasParents().find({is_preferred: 1});
    }

    /**
     * @param ref ProductHasCategory instance
     * */
    async changePreferredRef(ref) {
        let prevRef = this.getPreferredRef();
        ref.set("is_preferred", 1);
        return ref.save().then(() => {
            if (prevRef) {
                prevRef.is_preferred = 0;
                prevRef.sync();
            }
        });
    }

    /**
     * Send request to link ids as parents
     * */
    async linkParents(ids = []) {
        let method = 'POST';
        let url = this.getURL(this.getRoute('link-parents'), this.getRouteParameters());
        let data = {ids};
        let params = {
            id: this.id,
            expand: 'categoryName',
            limit: 0
        };

        return this.createRequest({method, url, data, params}).send()
            .then((resp) => {
                this.getHasParents().replace(resp.getData("data").data);
            });
    }

    /**
     * @return BaseStore - The configured store of hasProperty rmodels
     * */
    getProperties(options) {
        if (!this._propertiesStoreInstance) {
            this._propertiesStoreInstance = new BaseStore(this.properties, {
                model: ProductProperty,
                ...options,
            });
            this.fetchProperties();
        }

        return this._propertiesStoreInstance;
    }

    /**
     * Send request to fetch props
     * */
    async fetchProperties() {
        let method = 'GET';
        let url = this.getURL(this.getRoute('properties'), this.getRouteParameters());
        let params = {
            id: this.id
        };
        let data = {};
        this.createRequest({method, url, data, params}).send()
            .then((resp) => {
                this.getProperties().replace(resp.getData("data"));
            });
    }

    /**
     * Send request to set properties
     * @param data Array
     * @return Deferred
     * */
    async setProperties(data) {
        let method = 'POST';
        let url = this.getURL(this.getRoute('properties'), this.getRouteParameters());
        let params = {
            id: this.id
        };

        return this.createRequest({method, url, data, params}).send()
            .then((resp) => {
                this.getProperties().replace(resp.getData("data"));
            });
    }
}

/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";

export default class ProductHasProperty extends BaseModel {
    static entityName = 'product-has-property';

    defaults() {
        return {
            ...super.defaults(),
            display_order: 1,
            value: "",
            product_id: null,
            product_property_id: null,
            group_name: null
        }
    }
}
